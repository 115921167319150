import * as React from 'react'
import Layout from '../components/layout'
import { hello } from '../styles/index.module.css'

const NotFoundPage = () => {
  return (
    <Layout align="center" valign="center">
      <h1 id={hello} className="display-2">Not Found</h1>
      <p className="lead align-self-center">Sorry, we couldn't find that page.</p>
    </Layout>
  )
}

export default NotFoundPage
